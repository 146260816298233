import { useTranslation } from "react-i18next";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from "@material-ui/core";
import {
  fetchMembershipsByOrganizationId,
  removeMembership,
} from "../../../../../redux/organizationMembership/action";
import { useDispatch } from "react-redux";
import { showDialog } from "../../../../../redux/dialogs/actions";

/**
 * Component for rendering a list of organization memberships.
 * @param {Object} props - Component props.
 * @param {Array} props.memberships - List of organization memberships.
 * @param {string} props.organizationId - ID of the organization.
 * @returns {JSX.Element} - Rendered component.
 */
export default function MembershipsList({ memberships, organizationId }) {
  const { t } = useTranslation(["common", "organization"]);
  const dispatch = useDispatch();

  /**
   * Handler function for removing a membership.
   * @param {Object} membership - The membership to be removed.
   * @param {string} title - Title for the dialog box.
   */
  const removeMembershipHandler = async (membership, title) => {
    dispatch(
      showDialog({
        title: title,
        message: t("membership.revokeMembershipConformationText", {
          firstname: membership?.firstname,
          lastname: membership?.lastname,
          ns: "organization",
        }),
        action: async () => {
          await dispatch(removeMembership(membership?.id));
          await dispatch(fetchMembershipsByOrganizationId(organizationId));
        },
      })
    );
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>
              {t("profile.firstName", { ns: "common" })}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {t("profile.lastName", { ns: "common" })}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {t("profile.mail", { ns: "common" })}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {t("membership.status", { ns: "organization" })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {memberships?.map((membership) => {
            return (
              <TableRow key={membership.id}>
                <TableCell>{membership.firstname}</TableCell>
                <TableCell>{membership.lastname}</TableCell>
                <TableCell>{membership.email}</TableCell>
                <TableCell>
                  {membership.status === "pending"
                    ? t("membership.invited", {
                        ns: "organization",
                      })
                    : t("membership.member", {
                        ns: "organization",
                      })}
                </TableCell>
                <TableCell>
                  {membership.status === "pending" ? (
                    <Button
                      size="large"
                      variant="outlined"
                      color="secondary"
                      style={{ width: "100%" }}
                      onClick={() =>
                        removeMembershipHandler(
                          membership,
                          t("membership.revokeMembershipInvitation", {
                            ns: "organization",
                          })
                        )
                      }
                    >
                      {t("membership.revokeMembershipInvitation", {
                        ns: "organization",
                      })}
                    </Button>
                  ) : (
                    <Button
                      id="revoke-membership"
                      size="large"
                      variant="outlined"
                      color="secondary"
                      style={{ width: "100%" }}
                      onClick={() =>
                        removeMembershipHandler(
                          membership,
                          t("membership.revokeMembership", {
                            ns: "organization",
                          })
                        )
                      }
                    >
                      {t("membership.revokeMembership", { ns: "organization" })}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

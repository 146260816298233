import { Box, Button, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../../models/enums/trainingType.enum";
import { clearDocuments } from "../../../../redux/documents/actions";
import { clearEditEvent } from "../../../../redux/events/actions";
import { clearPreview } from "../../../../redux/preview/actions";
import { clearSpeakers } from "../../../../redux/speaker/actions";
import { clearTemplateEvent } from "../../../../redux/templates/events/actions";
import { resetDefaultTasksForEventPreview } from "../../../../redux/templates/tasks/actions";
import { Utils } from "../../../../services/utils";
import CustomSelect from "../../../forms/selects/CustomSelect";
import routes from "../../../../routing/routes";
import "../../../../pages/core/event/EventListPage.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CreateTrainingFormProps {}

const CreateTrainingForm: React.FC<CreateTrainingFormProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const theme = useTheme();
  const medium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid item xs={12} sm={10} md={8} lg={6}>
      <Formik
        onSubmit={(data: { createTraining: TrainingType }) => {
          // TODO: call the routes (elearning, event, ...)
          // based on the selected value push to route for training creation
          dispatch(clearEditEvent());
          dispatch(clearSpeakers());
          dispatch(clearDocuments());
          dispatch(clearTemplateEvent());
          dispatch(clearPreview());
          dispatch(resetDefaultTasksForEventPreview());
          if (data.createTraining === TrainingType.DefaultEvent) {
            history.push(routes.event_create);
          } else if (data.createTraining === TrainingType.ELearning) {
            history.push(routes.elearning_create);
          } else if (data.createTraining === TrainingType.BlendedLearning) {
            history.push(routes.blended_learning_create);
          } else {
            history.push(routes.event_create);
          }
        }}
        enableReinitialize
        initialValues={{
          createTraining: TrainingType.DefaultEvent,
        }}
      >
        {({ values }) => (
          <Form>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              flexDirection="row"
            >
              <CustomSelect
                name="createTraining"
                id="create-training-select"
                label="Fortbildungsmaßnahme"
                noEmptyDefaultValue
                defaultText={mapToTrainingTypeString(TrainingType.DefaultEvent)}
                labelId="create-training-select-label"
                options={(
                  Object.values(TrainingType).filter(
                    (value) => typeof value === "number"
                  ) as TrainingType[]
                ).map((item) => {
                  return {
                    key: Utils.getEnumKeyByValue(TrainingType, item),
                    value: item,
                    label: mapToTrainingTypeString(item),
                  };
                })}
              />
              <Button
                id="create-new-event"
                style={{ marginLeft: medium ? "2em" : "1em" }}
                className="fill-full-width"
                size="medium"
                variant="contained"
                color="primary"
                type="submit"
              >
                Neue Anlage
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default CreateTrainingForm;

// TODO no translation yet

import React, { Dispatch, SetStateAction } from "react";
import { AddMembershipDocumentForm } from "../profile-settings/membership-documents/AddMembershipDocumentForm";
import { MembershipDocumentRequestDto } from "../../../models/groupMembership";
import { useTranslation } from "react-i18next";
import { FileObject } from "material-ui-dropzone";
import { Typography } from "@material-ui/core";
import DOMPurify from "dompurify";

interface GroupMembershipDocumentSectionProps {
  groupMembershipDocument: MembershipDocumentRequestDto;
  setGroupMembershipDocument: Dispatch<SetStateAction<MembershipDocumentRequestDto>>;
  files: FileObject[];
  setFiles: Dispatch<SetStateAction<FileObject[]>>;
}

export const GroupMembershipDocumentSection: React.FC<
  GroupMembershipDocumentSectionProps
> = (props) => {
  const { groupMembershipDocument, setGroupMembershipDocument, files, setFiles } = props;
  const { t } = useTranslation(["customer"]);

  const createSafeHTML = (htmlString: string) => {
    return { __html: DOMPurify.sanitize(htmlString) };
  };

  return (
    <>
      <div className="RegistrationAdress" style={{ width: "100%" }}>
        <Typography
          dangerouslySetInnerHTML={createSafeHTML(
            t("docUpload.instructionHeadline", { ns: "customer" })
          )}
        />
      </div>
      <AddMembershipDocumentForm
        isRegistrationPage
        setGroupMembershipDocument={setGroupMembershipDocument}
        groupMembershipDocument={groupMembershipDocument}
        files={files}
        setFiles={setFiles}
      />
    </>
  );
};

import MomentUtils from "@date-io/moment";
import { Box, Container } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/de"; // it is required to select default locale manually
import React from "react";
import { useLocation } from "react-router-dom";
import CoreRoutes from "../../routing/CoreRoutes";
import routes from "../../routing/routes";
import ScrollToTopButton from "../../routing/ScrollToTopButton";
import AlertDialog from "./dialog/AlertDialog";
import { Footer } from "./footer/Footer";
import { Header } from "./header/Header";
import BreadcrumbsLumos from "./header/navigation/breadcrumbs/BreacrumbsLumos";
import MaintenanceBanner from "./MaintenanceBanner";
import useFullWidth from "./useFullWidth";
import { useSelector } from "react-redux";
import SupportButton from "./support-button/SupportButton";
import { AppState } from "../../redux";

export const Layout: React.FC = () => {
  const location = useLocation();
  const { isFullWidth, routeDoesNotMatchKnownRoutes } = useFullWidth();

  const auth = useSelector((state: AppState) => state.auth); // Login-Status prüfen

  return (
    <Box className="app-wrapper" data-testid="app-content">
      <Header />

      {location.pathname !== routes.home && !routeDoesNotMatchKnownRoutes && (
        <BreadcrumbsLumos />
      )}
      <MaintenanceBanner />
      <ScrollToTopButton />
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="de">
        <main className="main-content" data-testid="main-content" role="main">
          <Container
            maxWidth={isFullWidth ? "xl" : "lg"}
            style={isFullWidth ? { padding: 0 } : {}}
          >
            <Box py={!isFullWidth ? 3 : 0}>
              <CoreRoutes />
            </Box>
          </Container>
        </main>
      </MuiPickersUtilsProvider>
      <Footer />
      <AlertDialog disableBackdrop={false} />
      {auth.loggedIn ? <SupportButton /> : null}
    </Box>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import { CustomSelectArrow } from "../assets/icons/custom-svg-components/LumosSvgCollection";
import "./ScrollToTopButton.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ScrollToTopButtonProps {}

const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = () => {
  const [showScroll, setShowScroll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleScroll = useCallback(() => {
    const scrolled = window.scrollY > 0;
    setShowScroll(scrolled);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times

      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    // Simulate loading to ensure that the button is not displayed until the initial loading process is complete
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return !isLoading ? (
    <Button
      variant="contained"
      color="primary"
      id="scroll-to-top"
      title="scroll-to-top"
      className={clsx("scrollTop", showScroll ? "show-button" : "hide-button")}
      onClick={scrollTop}
    >
      <CustomSelectArrow
        style={{
          color: "#fff",
          stroke: "#fff",
          transform: "rotate(-90deg)",
          fontSize: "1.75rem",
        }}
      />
    </Button>
  ) : null;
};

export default ScrollToTopButton;
